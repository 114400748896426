<template>
    <b-row>
        <b-col cols="12">
            <b-overlay
                variant="white"
                :show="showLoading"
                spinner-variant="primary"
                blur="0"
                opacity=".75"
                rounded="sm"
            >
                <b-card>
                    <b-row class="m-0">
                        <b-col cols="12" md="6" xl="4">
                            <b-col cols="12" class="p-0">
                                <b-form-group label="Concepto" label-for="">
                                    <b-form-input v-model="equipmentDeleteRequest.concept" type="text" placeholder="Concepto"
                                        autocomplete="off" />
                                    <small v-if="validation.concept" class="text-danger w-100">
                                        {{ validation.concept }}
                                    </small>
                                </b-form-group>
                            </b-col>
                        </b-col>

                        <b-col cols="12" md="6" xl="4">
                            <b-form-group label="Tag de origen" label-for="">
                                <v-select v-model="equipmentDeleteRequest.origin_tag_id" label="code" :options="tags"
                                    :reduce="(option) => option.id">
                                    <template #no-options="{}">
                                        No hay tags.
                                    </template>
                                </v-select>
                                <small v-if="validation.origin_tag_id" class="text-danger w-100">
                                    {{ validation.origin_tag_id }}
                                </small>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" md="6" xl="4">
                            <b-form-group label="Tag de destino" label-for="">
                                <v-select v-model="equipmentDeleteRequest.destinatary_tag_id" label="code" :options="tags"
                                    :reduce="(option) => option.id">
                                    <template #no-options="{}">
                                        No hay tags.
                                    </template>
                                </v-select>
                                <small v-if="validation.destinatary_tag_id" class="text-danger w-100">
                                    {{ validation.destinatary_tag_id }}
                                </small>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12">
                            <b-form-group label="Observaciones" label-for="">
                                <b-form-textarea v-model="equipmentDeleteRequest.description" type="text"
                                    placeholder="Observaciones" autocomplete="off" />
                                <small v-if="validation.description" class="text-danger w-100">
                                    {{ validation.description }}
                                </small>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="m-0" align-h="start">
                        <b-col cols="12">
                            <b-button variant="primary" class="mt-2 mr-1" @click="sendData">
                                Guardar Cambios
                            </b-button>
                            <b-button variant="outline-secondary" type="reset" class="mt-2" @click="cancel">
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-overlay>
        </b-col>
    </b-row>
</template>

<script>
import { BCard, BCol, BOverlay, BButton, BRow, BFormGroup, BFormInput, BFormTextarea } from 'bootstrap-vue'
import helpers from "@/resources/helpers/helpers"
import vSelect from "vue-select"
import { mapState } from "vuex"
import { createHelpers } from "vuex-map-fields"

const { mapFields } = createHelpers({
    getterType: 'equipmentDeleteRequest/getField',
    mutationType: 'equipmentDeleteRequest/updateField',
})

export default {
    name: "FormView",
    components: {
        BOverlay,
        BCol,
        BRow,
        BCard,
        BButton,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        vSelect,
    },
    data() {
        return {
            showLoading: false
        }
    },
    computed: {
        ...mapState('equipment', ['equipment']),
        ...mapState('equipmentDeleteRequest', ['validation']),
        ...mapState('tag', ['tags']),
        ...mapFields({ equipmentDeleteRequest: 'equipmentDeleteRequest'}),
    },
    created() {
        this.$store.commit('equipmentDeleteRequest/RESET_STATE')

        this.getEquipment()
    },
    beforeDestroy() {
        this.$store.commit('SET_PAGE_TITLE', null)
    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
        getEquipment() {
            this.showLoading = true

            this.$store.dispatch('equipment/getEquipment', {
                id: this.$route.params.id,
                relations: ['tags']
            }).then(response => {
                this.$store.commit('SET_PAGE_TITLE', ' Crear solicitud de baja de Equipo "'+ response.serial_number+'"')
                this.getTagsClient(response.client_id)
                this.showLoading = false

            }).catch(error => {
                this.showLoading = false
                console.log(error)

                helpers.showToast(error.message, 'MonitorIcon', 'danger')
            })
        },
        getTagsClient(client_id) {
            this.$store.dispatch(`tag/listClientTags`, client_id)
                .catch(error => {
                    console.log(error)
                })
        },
        sendData() {
            this.showLoading = true

            this.$store.dispatch(`equipmentDeleteRequest/create`, this.equipmentDeleteRequest)
                .then(response => {
                    this.showLoading = false
                    helpers.showToast('Petición de baja enviada!', 'MonitorIcon', 'success')
                    this.$router.push({ name: 'sheet_equipment', id: this.$route.params.client })
                })
                .catch(error => {
                    this.showLoading = false

                    console.log(error)
                })
        },
        cancel() {
            this.$router.push({ name: 'sheet_equipment', id: this.$route.params.id })
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
